import "./my-appointments.css";
import React, { useState } from "react";
import { Calender } from "../calender/calender";

export default function MyAppointments({ isMinimized, minimizeSidebar }) {
  const [showView, setShowView] = useState(true);

  const appointmentData = [
    {
      name: "Guss Fring",
      website: "gussOwns.com",
      time: "10.00 am",
      date: "Sepetember 25, 2024",
      tutor: "James",
      topic: "Details of Vales",
      schedule: "Scheduled 4 hours ago",
    },
    {
      name: "Saul Goodman",
      website: "goodmanClassesMath.com",
      time: "01.30 pm",
      date: "November 1, 2024",
      tutor: "Doakes",
      topic: "Inline inspection devices",
      schedule: "Scheduled 1 day ago",
    },
    {
      name: "Dexter Morgan",
      website: "bayHoubourPigzilla.com",
      time: "10.00 am",
      date: "November 28, 2024",
      tutor: "Debra",
      topic: "Abnormal operating conditions",
      schedule: "Scheduled less than hour ago",
    },
    {
      name: "Ross Mike",
      website: "mikeross_pigzilla.com",
      time: "11.00 am",
      date: "December 4, 2024",
      tutor: "Lundy",
      topic: "Details of Vales",
      schedule: "Scheduled 10 hours ago",
    },
    {
      name: "Walter White",
      website: "white_chem.com",
      time: "10.00 am",
      date: "January 5, 2025",
      tutor: "James",
      topic: "Details of Vales",
      schedule: "Scheduled 4 hours ago",
    },
    {
      name: "Jessy Pinkman",
      website: "jessey_new.com",
      time: "08.00 am",
      date: "January 11, 2025",
      tutor: "James",
      topic: "Details of Vales",
      schedule: "Scheduled 2 days ago",
    },
    {
      name: "Angel Batista",
      website: "drakes56.com",
      time: "10.00 am",
      date: "March 1, 2025",
      tutor: "James",
      topic: "Details of Vales",
      schedule: "Scheduled 1 week ago",
    },
  ];

  function toggleView() {
    setShowView(!showView);
  }
  return (
    <div className=" ">
      <h3 className="text-center appointments-title">Appointments</h3>
      <div className="toggle-switch" onClick={() => toggleView()}>
        <div className={`option ${!showView ? "active" : ""}`}>Calender</div>
        <div className={`option ${showView ? "active" : ""}`}>Tabular</div>
      </div>

      {showView === false && (
        <div>
          <Calender />
        </div>
      )}
      {showView === true && (
        <div className="tabular-div">
          {appointmentData.map((data, index) => (
            <div className="tabular-content" key={index}>
              <div>
                <div className="flexx-div10">
                  <svg
                    viewBox="0 0 512 512"
                    width="60px"
                    height="60px"
                    fill="#000000"
                  >
                    <path d="M399 384.2C376.9 345.8 335.4 320 288 320l-64 0c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z" />
                  </svg>
                  <div>
                    <div>
                      <b>{data.name}</b>
                    </div>
                    <div>{data.website}</div>
                  </div>
                </div>
                <div>
                  <div className="tabular-info-div mt-3">
                    <div className="tabular-info-left">
                      <div className="flexx-div10">
                        <svg
                          viewBox="0 0 512 512"
                          width="25px"
                          height="25px"
                          fill="#000000"
                        >
                          <path d="M176 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c-35.3 0-64 28.7-64 64l-40 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l40 0 0 56-40 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l40 0 0 56-40 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l40 0c0 35.3 28.7 64 64 64l0 40c0 13.3 10.7 24 24 24s24-10.7 24-24l0-40 56 0 0 40c0 13.3 10.7 24 24 24s24-10.7 24-24l0-40 56 0 0 40c0 13.3 10.7 24 24 24s24-10.7 24-24l0-40c35.3 0 64-28.7 64-64l40 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-40 0 0-56 40 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-40 0 0-56 40 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-40 0c0-35.3-28.7-64-64-64l0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40-56 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40-56 0 0-40zM160 128l192 0c17.7 0 32 14.3 32 32l0 192c0 17.7-14.3 32-32 32l-192 0c-17.7 0-32-14.3-32-32l0-192c0-17.7 14.3-32 32-32zm192 32l-192 0 0 192 192 0 0-192z" />
                        </svg>

                        <div>{data.topic}</div>
                      </div>
                      <div className="flexx-div10 mt-2">
                        <svg
                          viewBox="0 0 512 512"
                          width="20px"
                          height="20px"
                          fill="#000000"
                        >
                          <path d="M256 288A144 144 0 1 0 256 0a144 144 0 1 0 0 288zm-94.7 32C72.2 320 0 392.2 0 481.3c0 17 13.8 30.7 30.7 30.7l450.6 0c17 0 30.7-13.8 30.7-30.7C512 392.2 439.8 320 350.7 320l-189.4 0z" />
                        </svg>

                        <div className="mx-1">{data.tutor}</div>
                      </div>
                    </div>

                    <div className="tabular-info-right">
                      <div>{data.time}</div>
                      <div>
                        <svg
                          viewBox="0 0 512 512"
                          width="20px"
                          height="20px"
                          fill="#c013ff"
                        >
                          <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160 352 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l111.5 0c0 0 0 0 0 0l.4 0c17.7 0 32-14.3 32-32l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1L16 432c0 17.7 14.3 32 32 32s32-14.3 32-32l0-35.1 17.6 17.5c0 0 0 0 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.8c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352l34.4 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L48.4 288c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
                        </svg>
                      </div>
                      <div>{data.date}</div>
                    </div>
                  </div>
                  <div className="mt-2">{data.schedule}</div>
                  <div className="mt-2 tabular-info-options">
                    <div className="flexx-div2">
                      <svg
                        viewBox="0 0 512 512"
                        width="15px"
                        height="15px"
                        fill="#000000"
                      >
                        <path d="M463.5 224l8.5 0c13.3 0 24-10.7 24-24l0-128c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8l119.5 0z" />
                      </svg>
                      <span> Reschedule</span>
                    </div>
                    <div className="flexx-div2">
                      <svg
                        viewBox="0 0 384 512"
                        width="15px"
                        height="15px"
                        fill="#000000"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                      <span>Cancel</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
