import "./my-credentials.css";

export default function MyCredentials({ isMinimized, minimizeSidebar }) {
  const safetyCouncil = [
    {
      name: "19PHOTO",
      detail: "KBLCS Id Badge",
      yes: true,
      no: false,
      expiryDate: "",
    },
    {
      name: "19BH",
      detail: "Basic Plus",
      yes: true,
      no: false,
      expiryDate: "",
    },
    {
      name: "19OQPIG",
      detail: "Pipeline Pigging",
      yes: true,
      no: false,
      expiryDate: "",
    },
    {
      name: "19OQPIG",
      detail: "Pipeline Pigging",
      yes: true,
      no: false,
      expiryDate: "",
    },
  ];

  const veriforce = [
    {
      name: "CCT-007",
      detail: "Operate Valves",
      yes: true,
      no: false,
      expiryDate: "",
    },
    {
      name: "CCT-618",
      detail: "Install temporary marking of buried pipelines",
      yes: true,
      no: false,
      expiryDate: "",
    },
    {
      name: "CCT-661",
      detail: "Launching and/or Receiving internal Devices(Pigs)",
      yes: true,
      no: false,
      expiryDate: "",
    },
    {
      name: "SCE-094",
      detail: "Operate key valves to Manage Flow & Maintain DOT Compliance",
      yes: true,
      no: false,
      expiryDate: "15/4/2025",
    },
  ];
  return (
    <div className="cred-parent-div">
      <h2 className="credentials-title">My Credentials</h2>
      <div className="credentials-div">
        <div className="cred-tab-div">
          <b>Safety Council</b>

          <table className="mem-table mt-2">
            <thead>
              <tr>
                <th>Certification</th>
                <th>Details</th>
                <th>Expiration Date</th>
                <th>Yes</th>
                <th>No</th>
              </tr>
            </thead>

            <tbody>
              {safetyCouncil.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div>{item.name}</div>
                  </td>
                  <td>
                    <div>{item.detail}</div>
                  </td>
                  <td>
                    <div>{item.expiryDate ? item.expiryDate : "N/A"}</div>
                  </td>
                  <td>
                    <div>{item.yes ? "✓" : "-"}</div>
                  </td>
                  <td>
                    <div>{item.no ? "✓" : "-"}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="cred-tab-div">
          <div className="mt-5">
            <b>Veriforce</b>
          </div>

          <table className="mem-table mt-2">
            <thead>
              <tr>
                <th>Certification</th>
                <th>Details</th>
                <th>Expiration Date</th>
                <th>Yes</th>
                <th>No</th>
              </tr>
            </thead>

            <tbody>
              {veriforce.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div>{item.name}</div>
                  </td>
                  <td>
                    <div>{item.detail}</div>
                  </td>
                  <td>
                    <div>{item.expiryDate ? item.expiryDate : "N/A"}</div>
                  </td>
                  <td>
                    <div>{item.yes ? "✓" : "-"}</div>
                  </td>
                  <td>
                    <div>{item.no ? "✓" : "-"}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
