import "./layout.css";
import { useState } from "react";
import Header from "../../shared-components/header/header";
import Sidebar from "../../shared-components/sidebar/sidebar";
import MyCredentials from "../my-credentials/my-credentials";
import MyAppointments from "../my-appointments/my-appointments";
import Settings from "../settings/settings";
import Offset from "../offset-component/offset";

export default function Layout() {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [activeComponent, setActiveComponent] = useState("appointments");
  const [notificationBoolean, setnotificationBoolean] = useState(false);

  function minimizeSidebar() {
    setIsMinimized(!isMinimized);
  }

  function handleSidebarClick(component) {
    setActiveComponent(component);
  }

  function activatePassword() {
    setIsPassword(!isPassword);
  }

  function enableNotification() {
    setnotificationBoolean(!notificationBoolean);
  }
  return (
    <div className="layout-container">
      <div className="left-side">
        <Sidebar
          isMinimized={isMinimized}
          activeComponent={activeComponent}
          onSidebarClick={handleSidebarClick}
          showDiv={false}
        />
      </div>
      <div className="right-side">
        <Header
          isMinimized={isMinimized}
          minimizeSidebar={minimizeSidebar}
          enableNotification={enableNotification}
          isPassword={activatePassword}
          showDiv={true}
        />
        <div className="content">
          {activeComponent === "appointments" && (
            <MyAppointments onSidebarClick={handleSidebarClick} />
          )}
          {activeComponent === "credentials" && <MyCredentials />}
          {activeComponent === "settings" && <Settings />}
          {notificationBoolean === true && (
            <Offset enableNotification={enableNotification} />
          )}
        </div>

        {isPassword && (
          <div className="pw-div">
            <div className="pw-form-div">
              <div>
                <label htmlFor="">Current Password</label>
                <input type="text" />
              </div>
              <div>
                <label htmlFor="">New Password</label>
                <input type="text" />
              </div>
              <div>
                <label htmlFor="">Confirm Password</label>
                <input type="text" />
              </div>

              <div className="pw-buttons mt-4">
                <button onClick={activatePassword}>Cancel</button>
                <button onClick={activatePassword}>Confirm</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
