import "./login.css";
import { useState } from "react";
import LogoImage from "../../assets/images/PTA-Logo.png";
import { useNavigate } from "react-router-dom";

export default function Landingpage({ isMinimized, minimizeSidebar }) {
  // State to manage which form is displayed
  const [individualForm, setIndividualForm] = useState(true);
  const [corporateForm, setCorporateForm] = useState(false);

  const navigate = useNavigate();
  const handleLoginClick = (event) => {
    event.preventDefault();
    navigate("/layout"); // Redirects to the register page
  };

  // Function to toggle forms based on the login type
  const loginType = (data) => {
    if (data === "Individual") {
      setIndividualForm(true);
      setCorporateForm(false);
    } else {
      setIndividualForm(false);
      setCorporateForm(true);
    }
  };
  return (
    <div className="container-cus">
      <div className="box">
        <div className="nav nav-tabs nav-container" id="nav-tab" role="tablist">
          <button
            className="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
            onClick={() => loginType("Individual")}
          >
            <b>Individual</b>
          </button>

          <button
            className="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
            onClick={() => loginType("Corporate")}
          >
            <b>Corporate</b>
          </button>
        </div>

        <div className="loginform">
          <div className="logo-container mt-2">
            <img className="logo" src={LogoImage} alt="" />
          </div>

          {individualForm === true && (
            <div className="individual-format">
              <div className="mt-2 input-container">
                <div className="form-floating input-cus-width mb-3">
                  <input
                    type="email"
                    className="form-control input"
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label htmlFor="floatingInput label">Email address</label>
                </div>

                <div className="form-floating">
                  <input
                    type="password"
                    className="form-control input"
                    id="floatingPassword"
                    placeholder="Password"
                  />
                  <label htmlFor="floatingPassword label">Password</label>
                </div>
              </div>

              <div className="mt-2 alignCenter flexx-around">
                <button
                  className="btn btn-primary login-but mt-2"
                  onClick={handleLoginClick}
                >
                  Login
                </button>
              </div>

              <div className="mt-2 alignCenter flexx-around">
                <u className="reset mb-2">Reset Password?</u>
              </div>
            </div>
          )}

          {corporateForm === true && (
            <div className="corporate-format">
              <div className="input-container mt-2">
                <div className="form-floating input-cus-width mb-3">
                  <input
                    type="text"
                    className="form-control input"
                    id="floatingPassword"
                    placeholder="Password"
                  />
                  <label htmlFor="floatingPassword">Company Website</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control input"
                    id="floatingInput"
                    placeholder="name@example.com"
                    formControlName="userName"
                  />
                  <label htmlFor="floatingInput">Email address</label>
                </div>
                <div className="form-floating">
                  <input
                    type="password"
                    className="form-control input"
                    id="floatingPassword"
                    placeholder="Password"
                    formControlName="password"
                  />
                  <label htmlFor="floatingPassword">Password</label>
                </div>
              </div>
              <div className="alignCenter mt-2 flexx-around ">
                <button
                  className="btn btn-primary login-but mt-2"
                  routerLink="/home"
                  onClick={handleLoginClick}
                >
                  Login
                </button>
              </div>

              <div className="alignCenter mt-2 flexx-around">
                <u className="reset mb-2">Reset Password?</u>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
