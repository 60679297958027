import { useState, useEffect } from "react";
import "./organization.css";

export default function Organization() {
  const [data, setData] = useState([
    {
      name: "Idc Engineering",
      website: "https://www.idc-tech.com/",
      contact: "+1-309-383-3200 ",
      location: "Albuquerque, NY",
    },
    {
      name: "Caterpillar",
      website: "https://www.caterpillar.com/#",
      contact: "+1 (309) 675-2337.",
      location: "Peoria, IL",
    },
  ]);

  const [orgHistory, setOrgHistory] = useState(null);
  const hdata = [
    {
      name: "Idc Engineering",
      email: "ranji3344@mail.com",
      data: [
        {
          completed: "-",
          location: "Illinois, USA",
          empNo: 30,
          timing: "9.00am to 4.00pm",
          trainer: "James Patrick",
          plan: "Tier 1",
        },
      ],
    },
  ];

  const [formValues, setFormValues] = useState({
    name: "",
    website: "",
    contact: "",
    location: "",
  });

  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleEdit = (row) => {
    setFormValues(row);
    setIsEditing(true);
    enableForm(true);
  };

  const handleDelete = (name) => {
    setData(data.filter((item) => item.name !== name));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      setData(
        data.map((item) =>
          item.email === formValues.email ? formValues : item
        )
      );
      setIsEditing(false);
    } else {
      setData([...data, { ...formValues }]);
    }
    setFormValues({ name: "", website: "", contact: "", location: "" });
    enableForm(false);
  };

  const [formBoolean, setFormBoolean] = useState(false);
  const [historyBoolean, setHistoryBoolean] = useState(false);

  const enableForm = (value) => {
    if (value === false) {
      setFormValues({ name: "", website: "", contact: "", location: "" });
    }
    setFormBoolean(value);
    setHistoryBoolean(false);
  };

  useEffect(() => {
    if (!formBoolean) {
      setFormValues({
        name: "",
        email: "",
        contact: "",
        plan: "",
      });

      setIsEditing(false);
    }
  }, [formBoolean]);

  const enableHistory = (name, value) => {
    let userRecord = null;
    if (name != null) {
      userRecord = hdata.find((record) => record.name === name);
    }

    if (userRecord != null && userRecord !== undefined) {
      setOrgHistory(userRecord);
    } else {
      setOrgHistory(null);
    }

    if (value === true && userRecord) {
      setHistoryBoolean(value);
    } else if (value === false) {
      setHistoryBoolean(value);
      setOrgHistory(null);
    }
  };

  return (
    <div className="organizations-parent-div">
      <h3 className="mt-3">Organization Management</h3>
      {!formBoolean && !historyBoolean && (
        <div>
          <div className="organization-new">
            <button className="resume-butn" onClick={() => enableForm(true)}>
              Add
            </button>
          </div>
          <div className="organization-table-div mt-2">
            <table>
              <thead>
                <tr>
                  <th className="organization-th-20"></th>
                  <th className="organization-th">Name</th>
                  <th className="organization-th">Website</th>
                  <th className="organization-th">Contact No</th>
                  <th className="organization-th">Location</th>
                  <th className="organization-th-50"></th>
                  <th className="organization-th-50"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((org, index) => (
                  <tr key={index}>
                    <td
                      className="organization-th-20 cursor"
                      onClick={() => enableHistory(org.name, true)}
                    >
                      <svg
                        height="15"
                        width="15"
                        fill="#000000"
                        viewBox="0 0 512 512"
                      >
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                      </svg>
                    </td>
                    <td className="organization-td">{org.name}</td>
                    <td className="organization-td">{org.website}</td>
                    <td className="organization-td">{org.contact}</td>
                    <td className="organization-td">{org.location}</td>
                    <td className="user-th-50 cursor">
                      <svg
                        width="15"
                        height="15"
                        fill="#000000"
                        viewBox="0 0 512 512"
                        onClick={() => handleEdit(org)}
                      >
                        <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                      </svg>
                    </td>
                    <td className="user-th-50 cursor">
                      <svg
                        width="15"
                        height="15"
                        fill="#000000"
                        viewBox="0 0 448 512"
                        onClick={() => handleDelete(org.name)}
                      >
                        <path d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" />
                      </svg>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {formBoolean && (
        <div className="organization-form-parent-div">
          <form onSubmit={handleFormSubmit}>
            <h5 className="mt-3">Create / Edit Organization</h5>

            <div className="organization-form-div mt-4">
              <div>
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formValues.name}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="">Website</label>
                <input
                  type="text"
                  name="website"
                  value={formValues.website}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="">Contact No</label>
                <input
                  type="text"
                  name="contact"
                  value={formValues.contact}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="">Location</label>
                <input
                  type="text"
                  name="location"
                  value={formValues.location}
                  onChange={handleInputChange}
                />
              </div>
              <div className="organization-buttons mt-4">
                <button
                  type="button"
                  className="resume-butn"
                  onClick={() => enableForm(false)}
                >
                  Cancel
                </button>
                <button className="resume-butn" type="submit">
                  {isEditing ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}

      {historyBoolean && (
        <div className="organization-history-parent-div">
          <div className="history-header">
            <div
              className="history-close"
              onClick={() => enableHistory(null, false)}
            >
              X
            </div>
            <h5 className="mt-4">{orgHistory.name}'s' Information</h5>
          </div>
          <div className="">
            <span>Joined Date : </span>
            <span>21-04-2024</span>
          </div>
          {orgHistory != null &&
            orgHistory.data.map((record, index) => (
              <div className="organization-history-div mt-3" key={index}>
                <div>
                  <label htmlFor="">Plan</label>
                  <div>{record.plan}</div>
                </div>
                <div>
                  <label htmlFor="">Employee Count:</label>
                  <div>{record.empNo}</div>
                </div>
                <div>
                  <label htmlFor="">Completed on</label>
                  <div className="text-center">{record.completed}</div>
                </div>
                <div>
                  <label htmlFor="">Location</label>
                  <div>{record.location}</div>
                </div>
                <div>
                  <label htmlFor="">Timing</label>
                  <div>{record.timing}</div>
                </div>
                <div>
                  <label htmlFor="">Trainer</label>
                  <div>{record.trainer}</div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
