import "./dashboard.css";
import React, { useEffect } from "react";
import Highcharts from "highcharts";

export default function Dashboard() {
  useEffect(() => {
    if (document.getElementById("container")) {
      Highcharts.chart("container", {
        chart: {
          type: "pie",
        },
        title: {
          text: "People Data",
        },
        tooltip: {
          valueSuffix: "%",
        },
        // subtitle: {
        //   text: 'Source:<a href="https://www.mdpi.com/2072-6643/11/3/684/htm" target="_default">MDPI</a>',
        // },
        plotOptions: {
          series: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: [
              {
                enabled: true,
                distance: 20,
              },
              {
                enabled: true,
                distance: -40,
                format: "{point.percentage:.1f}%",
                style: {
                  fontSize: "1.2em",
                  textOutline: "none",
                  opacity: 0.7,
                },
                filter: {
                  operator: ">",
                  property: "percentage",
                  value: 10,
                },
              },
            ],
          },
        },
        series: [
          {
            name: "Percentage",
            colorByPoint: true,
            data: [
              // sliced: true,
              // selected: true,
              {
                name: "Tier 1",
                y: 55,
              },
              {
                name: "Tier 2",
                y: 35,
              },
              {
                name: "Tier 3",
                y: 10,
              },
            ],
          },
        ],
      });
    }
    if (document.getElementById("container1")) {
      Highcharts.chart("container1", {
        chart: {
          type: "pie",
        },
        title: {
          text: "Organization Data",
        },
        tooltip: {
          valueSuffix: "%",
        },
        // subtitle: {
        //   text: 'Source:<a href="https://www.mdpi.com/2072-6643/11/3/684/htm" target="_default">MDPI</a>',
        // },
        plotOptions: {
          series: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: [
              {
                enabled: true,
                distance: 20,
              },
              {
                enabled: true,
                distance: -40,
                format: "{point.percentage:.1f}%",
                style: {
                  fontSize: "1.2em",
                  textOutline: "none",
                  opacity: 0.7,
                },
                filter: {
                  operator: ">",
                  property: "percentage",
                  value: 10,
                },
              },
            ],
          },
        },
        series: [
          {
            name: "Percentage",
            colorByPoint: true,
            data: [
              // sliced: true,
              // selected: true,
              {
                name: "Idc Engineering",
                y: 55,
              },
              {
                name: "Caterpillar",
                y: 35,
              },
              {
                name: "Amazon Web",
                y: 10,
              },
              {
                name: "Pigzilla",
                y: 10,
              },
            ],
          },
        ],
      });
    }
  }, []);

  return (
    <div className="dashboard-parent-div">
      <h3 className="mt-3">Dashboard</h3>
      <div className="dashboard-chart-data">
        <div>
          <div id="container" style={{ width: "500px", height: "400px" }}></div>
        </div>
        <div>
          <div
            id="container1"
            style={{ width: "500px", height: "400px" }}
          ></div>
        </div>
      </div>
    </div>
  );
}
