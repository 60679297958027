import "./landing-page.css";
import LogoImage from "../../assets/images/PTA-Logo.png";
import AboutUsImage from "../../assets/images/about_us.jpg";
import AlwaysSafetyImage from "../../assets/images/always_safety.png";
import TrainingImage from "../../assets/images/training.jpg";
import SolutionsImage from "../../assets/images/solutionss.jpg";
import { useNavigate } from "react-router-dom";

export default function Landingpage({ isMinimized, minimizeSidebar }) {
  const navigate = useNavigate();
  const handleRegisterClick = (event) => {
    event.preventDefault();
    navigate("/register"); // Redirects to the register page
  };
  const handleLoginClick = (event) => {
    event.preventDefault();
    navigate("/login"); // Redirects to the register page
  };
  return (
    <div className="navbar-container">
      <div className="navbar">
        <img src={LogoImage} alt="Logo" className="navbar-logo" />
        <div className="nav-menu">
          <div className="nav-link-group">
            <a href="/">Corporate Partners</a>
            <a href="/">Corporate Affiliate</a>
            <a href="/">Corporate Sponsor</a>
            <a href="/">Contact us</a>
          </div>
          <div className="nav-link-group">
            <a href="/">About</a>
            <a href="/">Safety First</a>
            <a href="/">PTA Training</a>
            <a href="/">Solutions</a>
            <a href="/">Contact Us</a>
          </div>
          <div className="nav-auth">
            <a href="/" onClick={handleRegisterClick}>
              REGISTER
            </a>
            <a href="/" onClick={handleLoginClick}>
              LOGIN
            </a>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="wrapper">
          <div id="main" className="main-section">
            <div className="main-content">
              <h3 className="pad-bot-10">Developing Innovative Techniques</h3>
              <h1 className="section-heading">
                ACHIEVING QUALITY AND SAFETY STANDARDS
              </h1>
              <h1 className="section-heading">
                Making your pipelines & facilities safer.
              </h1>
            </div>
          </div>

          <div id="about" className="section">
            <div className="contents">
              <h2 className="section-heading">About</h2>
              <p>
                Pig Trackers of America was started as a need for qualified
                individuals to perform the required task’s efficiently and
                safely while preparing for specific types of internal
                inspections. We are working to create a safer environment by
                educating the work force by standardizing training. Our
                commitment is to bring awareness as to why you are performing
                certain task while tracking pigs and give a better understanding
                to why you are performing these task. From Sub Zero to extreme
                heat conditions, we cover it all.
              </p>
            </div>
            <div className="image">
              <img src={AboutUsImage} alt="" />
            </div>

            <div id="safety" className="section1">
              <div className="image">
                <img src={AlwaysSafetyImage} alt="" />
              </div>

              <div className="contents">
                <h2 className="section-heading">Safety First</h2>
                <p>
                  Safety is our #1 goal here at PTA. Bringing awareness to each
                  other from the launcher to receiver and all areas in between,
                  while you may be on the ROW preforming these task, you will be
                  on landowners' property. While performing these task it is
                  your responsibility for your safety and making sound
                  decisions. At time’s while performing these task you are
                  usually out there in the elements with your crew, or
                  independently at your location, with potential Landowner
                  issues to wildlife to extreme weather. Also, with the growing
                  concern of sabotage to the oil and gas industry we all need to
                  pay attention to our surroundings.
                </p>
              </div>
            </div>

            <div id="training" className="section1">
              <div className="contents">
                <h2 className="section-heading">PTA Training</h2>
                <p>
                  When signing up for PTA you will be prompted to enroll in a
                  className, then will prompted to choose a schedule date to
                  come in and attend in person with our professionals. This
                  instructor-led training with computer-based modules, included
                  general safety, professional skills development and providing
                  site-specific orientations when applicable. To accommodate the
                  ever-growing need for skilled labor. PTA created a skills
                  development training solution that features instruction’s,
                  hands-on training, integrated simulation’s, evaluations. That
                  focus on specific tasks with an emphasis on proper techniques,
                  safety hazard and recognition.
                </p>
              </div>
              <div className="image">
                <img src={TrainingImage} alt="" />
              </div>
            </div>

            <div id="solutions" className="section1">
              <div className="image">
                <img src={SolutionsImage} alt="" />
              </div>
              <div className="contents">
                <h2 className="section-heading">Solutions</h2>
                <p>
                  PTA has taken the field to the classNameroom. From here you
                  will be tested on your knowledge and skill to perform duties
                  pertaining to pig tracking. Being knowledge and skilled
                  verified that proper use of AGM boxes, and transmitter as well
                  as knowledge to safely access the ROW and how to position
                  vehicle’s in heavily trafficked roadways. By teaching, we
                  educate and train the pig tracker, which provides confirmation
                  to the hiring company the pig trackers are endorsed.
                </p>
              </div>
            </div>

            <div id="contact" className="section1">
              <div className="contents">
                <h2 className="section-heading">Contact Us</h2>
                <div style={{ display: "flex", gap: "3em" }}>
                  <div>
                    <div className="contact_info_align">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        width="30"
                        height="30"
                      >
                        <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM218 271.7L64.2 172.4C66 156.4 79.5 144 96 144H352c16.5 0 30 12.4 31.8 28.4L230 271.7c-1.8 1.2-3.9 1.8-6 1.8s-4.2-.6-6-1.8zm29.4 26.9L384 210.4V336c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V210.4l136.6 88.2c7 4.5 15.1 6.9 23.4 6.9s16.4-2.4 23.4-6.9z" />
                      </svg>
                      <p>
                        {" "}
                        Email: <br /> contact@pigtrackers.com
                      </p>
                    </div>
                    <div className="contact_info_align">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="25"
                        height="25"
                      >
                        <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                      </svg>
                      <p>
                        Phone: <br />
                        (123) 456-7890
                      </p>
                    </div>

                    <div className="contact_info_align">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        width="25"
                        height="25"
                      >
                        <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                      </svg>
                      <p>
                        Address: <br /> America
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      columnGap: "3em",
                      flexWrap: "wrap",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="30"
                      height="30"
                    >
                      <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM351.3 199.3v0c0 86.7-66 186.6-186.6 186.6c-37.2 0-71.7-10.8-100.7-29.4c5.3 .6 10.4 .8 15.8 .8c30.7 0 58.9-10.4 81.4-28c-28.8-.6-53-19.5-61.3-45.5c10.1 1.5 19.2 1.5 29.6-1.2c-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3c-9-6-16.4-14.1-21.5-23.6s-7.8-20.2-7.7-31c0-12.2 3.2-23.4 8.9-33.1c32.3 39.8 80.8 65.8 135.2 68.6c-9.3-44.5 24-80.6 64-80.6c18.9 0 35.9 7.9 47.9 20.7c14.8-2.8 29-8.3 41.6-15.8c-4.9 15.2-15.2 28-28.8 36.1c13.2-1.4 26-5.1 37.8-10.2c-8.9 13.1-20.1 24.7-32.9 34c.2 2.8 .2 5.7 .2 8.5z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="30"
                      height="30"
                    >
                      <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="30"
                      height="30"
                    >
                      <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="30"
                      height="30"
                    >
                      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      width="30"
                      height="30"
                    >
                      <path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
