import { useState, useEffect } from "react";
import "./training-sessions.css";

export default function TrainingSessions() {
  const [sessionHistory, setSessionHistory] = useState(null);
  const [data, setData] = useState([
    {
      id: "C1",
      course: "Tier 1",
      location: "Computer Systems Institute - Chicago",
      trainer: "Willis Patt",
      date: "2024-01-04",
      timing: "11.00 AM to 2.00PM ",
    },
    {
      id: "C2",
      course: "Tier 2",
      location: "Midwest Technical Institute - Texas",
      trainer: "Lewey James",
      date: "2024-01-11",
      timing: "12.00 AM to 4.00PM ",
    },
  ]);

  const hdata = [
    {
      name: "Tier 1",
      email: "ranji3344@mail.com",
      data: [
        {
          location: "Computer Systems Institute - Chicago",
          attend: 34,
          timing: "9.00am to 4.00pm",
          date: "01-12-2023",
          trainer: "James Patrick",
        },
        {
          location: "Computer Systems Institute - Chicago",
          attend: 20,
          date: "21-12-2023",
          timing: "9.00am to 4.00pm",
          trainer: "James Patrick",
        },
      ],
    },
  ];

  const [formValues, setFormValues] = useState({
    id: "",
    course: "",
    location: "",
    trainer: "",
    date: "",
    timing: "",
  });

  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleEdit = (row) => {
    setFormValues(row);
    setIsEditing(true);
    enableForm(true);
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      setData(
        data.map((item) => (item.id === formValues.id ? formValues : item))
      );

      setIsEditing(false);
    } else {
      setData([...data, { ...formValues }]);
    }
    setFormValues({
      id: "",
      course: "",
      location: "",
      trainer: "",
      date: "",
      timing: "",
    });
    enableForm(false);
  };

  const [formBoolean, setFormBoolean] = useState(false);
  const [historyBoolean, setHistoryBoolean] = useState(false);

  const enableForm = (value) => {
    if (value === false) {
      setFormValues({
        id: "",
        course: "",
        location: "",
        trainer: "",
        date: "",
        timing: "",
      });
    }
    setFormBoolean(value);
    setHistoryBoolean(false);
  };

  useEffect(() => {
    if (!formBoolean) {
      setFormValues({
        id: "",
        course: "",
        location: "",
        trainer: "",
        date: "",
        timing: "",
      });

      setIsEditing(false);
    }
  }, [formBoolean]);

  const enableHistory = (name, value) => {
    let userRecord = null;
    if (name != null) {
      userRecord = hdata.find((record) => record.name === name);
    }

    if (userRecord != null && userRecord !== undefined) {
      setSessionHistory(userRecord);
    } else {
      setSessionHistory(null);
    }

    if (value === true && userRecord) {
      setHistoryBoolean(value);
    } else if (value === false) {
      setHistoryBoolean(value);
      setSessionHistory(null);
    }
  };

  return (
    <div className="sessions-parent-div">
      <h3 className="mt-3">Session Management</h3>
      {!formBoolean && !historyBoolean && (
        <div>
          <div className="session-new">
            <button className="resume-butn" onClick={() => enableForm(true)}>
              Add
            </button>
          </div>
          <div className="session-table-div mt-2">
            <table>
              <thead>
                <tr>
                  <th className="session-th-20"></th>
                  <th className="session-th">Course Name</th>
                  <th className="session-th">Location</th>
                  <th className="session-th">Trainer</th>
                  <th className="session-th">Date</th>
                  <th className="session-th">Timing</th>
                  <th className="session-th-50"></th>
                  <th className="session-th-50"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((session, index) => (
                  <tr key={index}>
                    <td className="session-th-20 cursor">
                      <svg
                        height="15"
                        width="15"
                        fill="#000000"
                        viewBox="0 0 512 512"
                        onClick={() => enableHistory(session.course, true)}
                      >
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                      </svg>
                    </td>
                    <td className="session-td">{session.course}</td>
                    <td className="session-td">{session.location}</td>
                    <td className="session-td">{session.trainer}</td>
                    <td className="session-td">{session.date}</td>
                    <td className="session-td">{session.timing}</td>
                    <td className="session-th-50 cursor">
                      <svg
                        width="15"
                        height="15"
                        fill="#000000"
                        viewBox="0 0 512 512"
                        onClick={() => handleEdit(session)}
                      >
                        <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                      </svg>
                    </td>
                    <td className="session-th-50 cursor">
                      <svg
                        width="15"
                        height="15"
                        fill="#000000"
                        viewBox="0 0 448 512"
                        onClick={() => handleDelete(session.id)}
                      >
                        <path d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" />
                      </svg>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {formBoolean && (
        <div className="session-form-parent-div">
          <form onSubmit={handleFormSubmit}>
            <h5 className="mt-3">{isEditing ? "Edit" : "Add"} Session</h5>

            <div className="session-form-div mt-4">
              {!isEditing && (
                <div>
                  <label htmlFor="">Id</label>
                  <input
                    type="text"
                    name="id"
                    value={formValues.id}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              <div>
                <label htmlFor="">Course</label>
                <select
                  name="course"
                  value={formValues.course}
                  onChange={handleInputChange}
                >
                  <option value="">Select Plan</option>
                  <option value="Tier 1">Tier 1</option>
                  <option value="Tier 2">Tier 2</option>
                  <option value="Tier 3">Tier 3</option>
                </select>
              </div>
              <div>
                <label htmlFor="">Location</label>
                <input
                  type="text"
                  name="location"
                  value={formValues.location}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="">Trainer</label>
                <input
                  type="text"
                  name="trainer"
                  value={formValues.trainer}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="">Date</label>
                <input
                  type="date"
                  name="date"
                  value={formValues.date}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="">Time</label>
                <input
                  type="text"
                  name="timing"
                  value={formValues.timing}
                  onChange={handleInputChange}
                />
              </div>
              <div className="session-buttons">
                <button
                  type="button"
                  className="resume-butn"
                  onClick={() => enableForm(false)}
                >
                  Cancel
                </button>
                <button className="resume-butn" type="submit">
                  {isEditing ? "Update" : "Add"} Session
                </button>
              </div>
            </div>
          </form>
        </div>
      )}

      {historyBoolean && (
        <div className="session-history-parent-div mt-4">
          <div className="history-header">
            <div
              className="history-close"
              onClick={() => enableHistory(null, false)}
            >
              X
            </div>
            <h5 className="">{sessionHistory.name}'s Information</h5>
          </div>
          <div className="">
            <span>First Session Date : </span>
            <span>01-12-2023</span>
          </div>
          {sessionHistory != null &&
            sessionHistory.data.map((record, index) => (
              <div className="session-history-div mt-3" key={index}>
                <div>
                  <label htmlFor="">Location</label>
                  <div>{record.location}</div>
                </div>
                <div>
                  <label htmlFor="">People Attended</label>
                  <div className="text-center">{record.attend}</div>
                </div>
                <div>
                  <label htmlFor="">Date</label>
                  <div>{record.date}</div>
                </div>
                <div>
                  <label htmlFor="">Timing</label>
                  <div>{record.timing}</div>
                </div>
                <div>
                  <label htmlFor="">Trainer</label>
                  <div>{record.trainer}</div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
