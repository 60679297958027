import { useState, useEffect } from "react";
import "./subscribers.css";

export default function Subscriber() {
  const [userHistory, setUserHistory] = useState(null);
  const [data, setData] = useState([
    {
      name: "Ranjith",
      email: "ranji3344@mail.com",
      contact: "5566788990",
      plan: "Tier 1",
    },
    {
      name: "James",
      email: "jamesKelly@mail.com",
      contact: "9898989898",
      plan: "Tier 2",
    },
  ]);

  const hdata = [
    {
      name: "Ranjith",
      email: "ranji3344@mail.com",
      data: [
        {
          completed: "-",
          location: "Illinois, USA",
          timing: "9.00am to 4.00pm",
          trainer: "James Patrick",
          plan: "Tier 1",
        },
      ],
    },
  ];

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    contact: "",
    plan: "",
  });

  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      setData(
        data.map((item) =>
          item.email === formValues.email ? formValues : item
        )
      );
      setIsEditing(false);
    } else {
      setData([...data, { ...formValues }]);
    }
    setFormValues({ name: "", email: "", contact: "", plan: "" });
    enableForm(false);
  };

  const [formBoolean, setFormBoolean] = useState(false);
  const [historyBoolean, setHistoryBoolean] = useState(false);

  const enableForm = (value) => {
    if (value === false) {
      setFormValues({ name: "", email: "", contact: "", plan: "" });
    }
    setFormBoolean(value);
    setHistoryBoolean(false);
  };

  useEffect(() => {
    if (!formBoolean) {
      setFormValues({
        name: "",
        email: "",
        contact: "",
        plan: "",
      });

      setIsEditing(false);
    }
  }, [formBoolean]);

  const enableHistory = (email, value) => {
    let userRecord = null;
    if (email != null) {
      userRecord = hdata.find((record) => record.email === email);
    }

    if (userRecord != null && userRecord !== undefined) {
      setUserHistory(userRecord);
    } else {
      setUserHistory(null);
    }

    if (value === true && userRecord) {
      setHistoryBoolean(value);
    } else if (value === false) {
      setHistoryBoolean(value);
      setUserHistory(null);
    }
  };

  return (
    <div className="users-parent-div">
      <h3 className="mt-3">Subscriber Management</h3>
      {!formBoolean && !historyBoolean && (
        <div>
          {/* <div className="user-new">
            <button className="resume-butn" onClick={() => enableForm(true)}>
              Add
            </button>
          </div> */}
          <div className="user-table-div mt-4">
            <table>
              <thead>
                <tr>
                  <th className="user-th-20"></th>
                  <th className="user-th">Name</th>
                  <th className="user-th">Email</th>
                  <th className="user-th">Contact No</th>
                  <th className="user-th">Current Course</th>
                </tr>
              </thead>
              <tbody>
                {data.map((user, index) => (
                  <tr key={index}>
                    <td className="user-th-20 cursor">
                      <svg
                        height="15"
                        width="15"
                        fill="#000000"
                        viewBox="0 0 512 512"
                        onClick={() => enableHistory(user.email, true)}
                      >
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                      </svg>
                    </td>
                    <td className="user-td">{user.name}</td>
                    <td className="user-td">{user.email}</td>
                    <td className="user-td">{user.contact}</td>
                    <td className="user-td">{user.plan}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {formBoolean && (
        <div className="user-form-parent-div">
          <form onSubmit={handleFormSubmit}>
            <h5 className="mt-3">{isEditing ? "Edit" : "Add"} User</h5>

            <div className="user-form-div mt-3">
              <div>
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formValues.name}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={formValues.email}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="">Contact No</label>
                <input
                  type="text"
                  name="contact"
                  value={formValues.contact}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="">Plan</label>

                <select
                  name="plan"
                  value={formValues.plan}
                  onChange={handleInputChange}
                >
                  <option value="">Select Plan</option>
                  <option value="Tier 1">Tier 1</option>
                  <option value="Tier 2">Tier 2</option>
                  <option value="Tier 3">Tier 3</option>
                </select>
              </div>
              <div className="user-buttons mt-4">
                <button
                  type="button"
                  className="resume-butn"
                  onClick={() => enableForm(false)}
                >
                  Cancel
                </button>
                <button className="resume-butn" type="submit">
                  {isEditing ? "Update" : "Add"} User
                </button>
              </div>
            </div>
          </form>
        </div>
      )}

      {historyBoolean && (
        <div className="user-history-parent-div mt-4">
          <div className="history-header">
            <div
              className="history-close"
              onClick={() => enableHistory(null, false)}
            >
              X
            </div>
            <h5 className="">{userHistory.name}'s Information</h5>
          </div>
          <div className="">
            <span>Joined Date : </span>
            <span>21-04-2024</span>
          </div>
          {userHistory != null &&
            userHistory.data.map((record, index) => (
              <div className="user-history-div mt-3" key={index}>
                <div>
                  <label htmlFor="">Plan</label>
                  <div>{record.plan}</div>
                </div>
                <div>
                  <label htmlFor="">Completed on</label>
                  <div className="text-center">{record.completed}</div>
                </div>
                <div>
                  <label htmlFor="">Location</label>
                  <div>{record.location}</div>
                </div>
                <div>
                  <label htmlFor="">Timing</label>
                  <div>{record.timing}</div>
                </div>
                <div>
                  <label htmlFor="">Trainer</label>
                  <div>{record.trainer}</div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
