import { useState } from "react";
import "./settings.css";

export default function Settings({ isMinimized, minimizeSidebar }) {
  const [settingType, setSettingType] = useState("profile");

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isCollapsed1, setIsCollapsed1] = useState(true);
  const [isCollapsed2, setIsCollapsed2] = useState(true);

  // Function to toggle the collapse state
  const toggleCollapse = (data) => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleCollapse1 = (data1) => {
    setIsCollapsed1(!isCollapsed1);
  };

  const toggleCollapse2 = (data2) => {
    setIsCollapsed2(!isCollapsed2);
  };

  const data = [
    {
      Name: "Ranjith",
      "Company Name": "IDC Engineering India",
      DOB: "21-Apr-1998",
      Location: "Chennai",
      Address: "1/88 West Tambaram, Chennai - 07",
      Country: "India",
      Email: "ranjithkumar_l@idcei.com ",
      "Phone No": "8110917899",
      Tier: "Tier - 1",
      "Home Location": "Chennai",
      "Able to travel": "Yes",
      "Years of Exp": "2 years",
    },
  ];

  function handleSettingChange(type) {
    return () => setSettingType(type);
  }

  return (
    <div className="settings-main-container">
      <div className="settings-left-side">
        <div
          className={settingType === "profile" ? "selected-setting" : ""}
          onClick={handleSettingChange("profile")}
        >
          My Profile
        </div>
        <div
          className={settingType === "membership" ? "selected-setting" : ""}
          onClick={handleSettingChange("membership")}
        >
          Membership
        </div>
        <div
          className={settingType === "payment" ? "selected-setting" : ""}
          onClick={handleSettingChange("payment")}
        >
          Payment
        </div>
      </div>
      <div className="settings-right-side">
        {settingType === "profile" && (
          <div>
            <h2 className="profile-title">My Profile</h2>
            <div className="profile-header">
              <div className="ph-left">
                <svg
                  viewBox="0 0 512 512"
                  height="100px"
                  width="100px"
                  fill="#000000"
                >
                  <path d="M399 384.2C376.9 345.8 335.4 320 288 320l-64 0c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z" />
                </svg>
                <h2>Jake</h2>
                <span className="endoresed-icons">
                  <svg
                    className="cursor"
                    viewBox="0 0 512 512"
                    height="15px"
                    width="15px"
                    fill="#000000"
                  >
                    <path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2l144 0c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48l-97.5 0c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3l0-38.3 0-48 0-24.9c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192l64 0c17.7 0 32 14.3 32 32l0 224c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32L0 224c0-17.7 14.3-32 32-32z" />
                  </svg>

                  <svg
                    className="cursor"
                    viewBox="0 0 512 512"
                    height="15px"
                    width="15px"
                    fill="#000000"
                  >
                    <path d="M313.4 479.1c26-5.2 42.9-30.5 37.7-56.5l-2.3-11.4c-5.3-26.7-15.1-52.1-28.8-75.2l144 0c26.5 0 48-21.5 48-48c0-18.5-10.5-34.6-25.9-42.6C497 236.6 504 223.1 504 208c0-23.4-16.8-42.9-38.9-47.1c4.4-7.3 6.9-15.8 6.9-24.9c0-21.3-13.9-39.4-33.1-45.6c.7-3.3 1.1-6.8 1.1-10.4c0-26.5-21.5-48-48-48l-97.5 0c-19 0-37.5 5.6-53.3 16.1L202.7 73.8C176 91.6 160 121.6 160 153.7l0 38.3 0 48 0 24.9c0 29.2 13.3 56.7 36 75l7.4 5.9c26.5 21.2 44.6 51 51.2 84.2l2.3 11.4c5.2 26 30.5 42.9 56.5 37.7zM32 384l64 0c17.7 0 32-14.3 32-32l0-224c0-17.7-14.3-32-32-32L32 96C14.3 96 0 110.3 0 128L0 352c0 17.7 14.3 32 32 32z" />
                  </svg>
                </span>
              </div>
              <div className="ph-right">
                <div className="resume-div">
                  <button className="resume-butn">Upload Resume</button>
                </div>
                <div className="pinfo-form mt-2">
                  <h4 className="profile-title">Personal Details</h4>
                  <div className="personal-data-list mt-2">
                    {data.map((item, index) => (
                      <div key={index} className="data-item">
                        {Object.keys(item).map((key) => (
                          <div key={key} className="data-pair">
                            <strong className="">{key}:</strong>{" "}
                            <div>{item[key]}</div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {settingType === "membership" && (
          <div>
            <div>
              <div>
                <h2 className="profile-title">Membership</h2>
                <b>Membership Details</b>

                <table className="mem-table mt-4">
                  <thead>
                    <tr>
                      <th>Membership Name </th>
                      <th>Cost</th>
                      <th>Activated Date</th>
                      <th>Expiry Date</th>
                      <th>Expiry In</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <div>Business Pro Plan</div>
                      </td>
                      <td>
                        <div>$300.0</div>
                      </td>
                      <td>
                        <div>August 01, 2024</div>
                      </td>
                      <td>
                        <div>January 30, 2025</div>
                      </td>
                      <td>
                        <div>182 days</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div></div>
          </div>
        )}
        {settingType === "payment" && (
          <div>
            <h2 className="profile-title">Payment</h2>
            <div className="renew-mem">
              <button className="resume-butn ">Renew Membership</button>
            </div>
            <div
              style={{
                height: isCollapsed ? "50px" : "auto",
              }}
              className="box-shadow collapsing-div mt-2"
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "flex-start",
                }}
              >
                <div
                  onClick={() => toggleCollapse("planDet")}
                  style={{ cursor: "pointer" }}
                >
                  {isCollapsed ? "▼" : "▲"}{" "}
                </div>
                <h5>Plan Details :</h5>
              </div>

              <div
                style={{
                  opacity: isCollapsed ? 0 : 1,
                  height: isCollapsed ? "0px" : "auto",
                }}
              >
                <table>
                  <thead>
                    <tr>
                      <th>Plan Name</th>
                      <th>Activated Date</th>
                      <th>Expiry Date</th>
                      <th>Expiry In</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Yearly Plan</td>
                      <td>23-05-2024</td>
                      <td>28-12-2024</td>
                      <td>40 days</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              style={{
                height: isCollapsed1 ? "50px" : "auto", // Set height based on state
              }}
              className="box-shadow collapsing-div mt-3"
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "flex-start",
                }}
              >
                <div
                  onClick={() => toggleCollapse1("billAddress")}
                  style={{ cursor: "pointer" }}
                >
                  {isCollapsed1 ? "▼" : "▲"}{" "}
                </div>
                <h5>Billing Address :</h5>
              </div>

              <div
                style={{
                  opacity: isCollapsed1 ? 0 : 1,
                  height: isCollapsed1 ? "0px" : "auto",
                  overflow: "hidden",
                  transition: "opacity 0.3s ease",
                  marginLeft: "20px",
                }}
              >
                1/88 West Tambaram, Chennai - 07
              </div>
            </div>
            <div
              style={{
                height: isCollapsed2 ? "50px" : "auto",
              }}
              className="box-shadow collapsing-div mt-3"
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "flex-start",
                }}
              >
                <div
                  onClick={() => toggleCollapse2("payments")}
                  style={{ cursor: "pointer" }}
                >
                  {isCollapsed2 ? "▼" : "▲"}{" "}
                </div>
                <h5>Payment Information :</h5>
              </div>

              <div
                style={{
                  opacity: isCollapsed2 ? 0 : 1,
                  height: isCollapsed2 ? "0px" : "auto",
                  transition: "opacity 0.3s ease",
                  marginLeft: "20px",
                }}
              >
                <div>Jake Williams</div>
                <div>State Bank of India</div>
                <div>8789787xxxxx90</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
