import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./calender.css";

const events = [
  { title: "Drakes Andrew - 10.00 am", date: "2024-09-18", color: "#05cf68" },
  { title: "James dott - 11.00am", date: "2024-09-19", color: "#05cf68" },
  { title: "Dean Joe - 11.00am", date: "2024-10-05", color: "#05cf68" },
  { title: "Michael Scott - 11.00am", date: "2024-10-08", color: "#05cf68" },
];

export function Calender() {
  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        weekends={true}
        events={events}
        eventContent={renderEventContent}
      />
    </div>
  );
}

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}
