import "./admin-login.css";
import LogoImage from "../../assets/images/PTA-Logo.png";
import { useNavigate } from "react-router-dom";

export default function AdminLogin() {
  const navigate = useNavigate();
  const handleLoginClick = (event) => {
    event.preventDefault();
    navigate("/home"); // Redirects to the register page
  };

  return (
    <div className="admin-container-cus">
      <div className="box">
        <div className="loginform">
          <div className="logo-container mt-2">
            <img className="logo" src={LogoImage} alt="" />
          </div>

          <div className="individual-format">
            <div className="mt-2 input-container">
              <div className="form-floating input-cus-width mb-3">
                <input
                  type="email"
                  className="form-control input"
                  id="floatingInput"
                  placeholder="name@example.com"
                />
                <label htmlFor="floatingInput label">Email address</label>
              </div>

              <div className="form-floating">
                <input
                  type="password"
                  className="form-control input"
                  id="floatingPassword"
                  placeholder="Password"
                />
                <label htmlFor="floatingPassword label">Password</label>
              </div>
            </div>

            <div className="mt-2 alignCenter flexx-around">
              <button
                className="btn btn-primary login-but mt-2"
                onClick={handleLoginClick}
              >
                Login
              </button>
            </div>

            <div className="mt-2 alignCenter flexx-around">
              <u className="reset mb-2">Reset Password?</u>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
