import "./offset.css";

export default function Offset({ enableNotification }) {
  return (
    <div className="main-container">
      <div className="close" onClick={() => enableNotification()}>
        x
      </div>

      <h3 className="text-center notify-title">Notification</h3>

      <div className="notify-card mt-3">
        Your yearly subscription is about to ru out. Please click membership to
        renew
      </div>
      <div className="notify-card mt-3">
        A company is looking to hire. Are you interested ?
      </div>
      <div className="notify-card mt-3">
        Please finish your profile by uploading your resume.
      </div>
    </div>
  );
}
