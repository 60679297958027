import "./admin-home.css";
import Header from "../../shared-components/header/header";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../shared-components/sidebar/sidebar";

export default function AdminHome() {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [activeComponent, setActiveComponent] = useState("dashboard");

  function minimizeSidebar() {
    setIsMinimized(!isMinimized);
  }

  function activatePassword() {
    setIsPassword(!isPassword);
  }

  function handleSidebarClick(component) {
    setActiveComponent(component);
  }

  return (
    <div className="layout-container">
      <div className="left-side">
        <div className="left-side">
          <Sidebar
            isMinimized={isMinimized}
            activeComponent={activeComponent}
            onSidebarClick={handleSidebarClick}
            showDiv={true}
          />
        </div>
      </div>
      <div className="right-side">
        <Header
          isMinimized={isMinimized}
          isPassword={activatePassword}
          minimizeSidebar={minimizeSidebar}
          showDiv={false}
        />
        <div className="content">
          <Outlet />
        </div>

        {isPassword && (
          <div className="pw-div">
            <div className="pw-form-div">
              <div>
                <label htmlFor="">Current Password</label>
                <input type="text" />
              </div>
              <div>
                <label htmlFor="">New Password</label>
                <input type="text" />
              </div>
              <div>
                <label htmlFor="">Confirm Password</label>
                <input type="text" />
              </div>

              <div className="pw-buttons mt-4">
                <button onClick={activatePassword}>Cancel</button>
                <button onClick={activatePassword}>Confirm</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
