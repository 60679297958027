import "./App.css";
import Layout from "./components/layouts/layout";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import MyAppointments from "./components/my-appointments/my-appointments";
import MyCredentials from "./components/my-credentials/my-credentials";
import Settings from "./components/settings/settings";
import "bootstrap/dist/css/bootstrap.min.css";
import Landingpage from "./components/landing-page/landing-page";
import Register from "./components/register/register";
import Login from "./components/login/login";
import AdminLogin from "./admin-components/admin-login/admin-login";
import AdminHome from "./admin-components/admin-home/admin-home";
import Dashboard from "./admin-components/dashboard/dashboard";
import User from "./admin-components/user/user";
import Organization from "./admin-components/organization/organization";
import TrainingLocation from "./admin-components/training-location/training-location";
import Trainers from "./admin-components/trainers/trainers";
import TrainingSessions from "./admin-components/training-sessions/training-sessions";
import Courses from "./admin-components/courses/courses";
import Subscriber from "./admin-components/subscriber/subscribers";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="/appointments" element={<MyAppointments />} />
          <Route path="/credentials" element={<MyCredentials />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/layout" element={<Layout />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/alogin" element={<AdminLogin />} />
          <Route path="/home" element={<AdminHome />} />

          <Route path="/home" element={<AdminHome />}>
            <Route index element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="users" element={<User />} />
            <Route path="organization" element={<Organization />} />
            <Route path="trainingLocation" element={<TrainingLocation />} />
            <Route path="trainers" element={<Trainers />} />
            <Route path="sessions" element={<TrainingSessions />} />
            <Route path="subscribers" element={<Subscriber />} />
            <Route path="courses" element={<Courses />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
