import "./register.css";
import { useState } from "react";
import { useForm } from "react-hook-form";
import LogoImage from "../../assets/images/PTA-Logo.png";
import { useNavigate } from "react-router-dom";
import visaImage from "../../assets/images/visa.png";
import masterImage from "../../assets/images/mastercard.png";
import amexImage from "../../assets/images/amex.png";

export default function Landingpage({ isMinimized, minimizeSidebar }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [planBoolean, setplanBoolean] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    mode: "onBlur", // Set validation to trigger on blur if needed
  });

  const onSubmit = (data) => {
    alert("Form submitted successfully: " + JSON.stringify(data));
  };

  // Function to handle step click
  const setCurrentStepHandler = (step) => {
    setCurrentStep(step);
  };

  const setPlanner = (step) => {
    setplanBoolean(step);
  };

  const navigate = useNavigate();

  const handleLoginClick = (event) => {
    event.preventDefault();
    navigate("/layout"); // Redirects to the register page
  };

  const handleLandingClick = (event) => {
    event.preventDefault();
    navigate("/"); // Redirects to the register page
  };

  return (
    <div>
      <nav className="navbar">
        <img src={LogoImage} alt="Logo" className="navbar-logo" />
        <div className="nav-menu"></div>
      </nav>

      <div className="container">
        <div className="form-content mt-5">
          <div className="form_body">
            {currentStep === 1 && (
              <form action="" onSubmit={handleSubmit(onSubmit)}>
                <div className="container_form">
                  <h2>Basic Info</h2>
                  <div className="billing-div">
                    <div>
                      <label>Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your name"
                        id="name"
                        {...register("name", { required: "Name is required" })}
                        onBlur={() => trigger("name")}
                      />
                      {errors.name && (
                        <span style={{ color: "red" }}>
                          {errors.name.message}
                        </span>
                      )}
                    </div>

                    <div>
                      <label>Email:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your email"
                        id="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Email is invalid",
                          },
                        })}
                        onBlur={() => trigger("email")}
                      />
                      {errors.email && (
                        <span style={{ color: "red" }}>
                          {errors.email.message}
                        </span>
                      )}
                    </div>

                    <div>
                      <label>Password:</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter your password"
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                        })}
                        onBlur={() => trigger("password")}
                      />
                      {errors.password && (
                        <span style={{ color: "red" }}>
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="btn_align">
                    <button type="submit" onClick={handleLandingClick}>
                      Back
                    </button>
                    {/* onClick={() => setCurrentStepHandler(2)} */}
                    <button
                      type="button"
                      className="mx-3"
                      onClick={() => setCurrentStepHandler(2)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
          <div className="form_body">
            {currentStep === 2 && (
              <div className="container_form">
                <h2>Payment Details</h2>
                <div className="sub_container">
                  <div>
                    <p>Order Summary</p>
                    <div className="form-group">
                      <label>First Payment:</label>
                      <div>
                        <div className="number_align"></div>
                        <div>-------------------------</div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Subtotal:</label>
                      <div>
                        <div className="number_align"></div>
                        <div>-------------------------</div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Sales Tax:</label>
                      <div>
                        <div className="number_align">
                          {" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <div>-------------------------</div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Order Total:</label>
                      <div>
                        <div className="number_align"></div>
                        <div>-------------------------</div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <p>Card Details</p>
                    <div className="">
                      <label>Cards:</label>
                      <select className="form-control" name="paymentMethod">
                        <option>Credit Card</option>
                        <option>Debit Card</option>
                      </select>
                    </div>

                    <div className="mt-3">
                      <div>Name on Card:</div>
                      <input type="text" className="form-control mt-1" />
                    </div>

                    <div className="mt-3">
                      <label>Card No:</label>
                      <div className="register-input-container mt-1">
                        <input
                          type="text"
                          className="flex0"
                          placeholder="Enter Card Number"
                        />
                        <img src={visaImage} alt="Visa" />
                        <img src={masterImage} alt="Mastercard" />
                        <img src={amexImage} alt="American Express" />
                      </div>
                    </div>

                    <div className="flex0">
                      <div>
                        <input
                          className="expiry"
                          type="text"
                          placeholder="MM/YYYY"
                        />
                      </div>
                      <div>
                        <input className="cvv" type="text" placeholder="CVV" />
                      </div>
                    </div>
                    {/* <div className="check-box-group">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="subscriptionConfirmation"
                      />
                      <label
                        className="form-check-label mt-2"
                        htmlFor="subscriptionConfirmation"
                      >
                        I want to subscribe to pig News and exclusives. Sign me
                        up
                      </label>
                    </div> */}
                    {/* <button className="btn_pay_now" onClick={handleLoginClick}>
                      Pay Now
                    </button> */}
                  </div>
                </div>
                <div className="btn_align">
                  <button
                    type="submit"
                    onClick={() => setCurrentStepHandler(1)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    onClick={() => setCurrentStepHandler(3)}
                    className="mx-3"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="form_body">
            {currentStep === 3 && (
              <div className="container_form">
                <h2>Billing Address</h2>
                <div className="billing-div">
                  <div className="">
                    <label>Company Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter company name"
                      required
                    />
                  </div>
                  <div className="">
                    <label>Address:</label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter company address"
                      style={{ resize: "none" }}
                      required
                    ></textarea>
                  </div>
                  <div className="">
                    <label>Phone:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter company no."
                      required
                    />
                  </div>
                </div>
                <div className="btn_align">
                  <button
                    type="submit"
                    onClick={() => setCurrentStepHandler(2)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    onClick={() => setCurrentStepHandler(4)}
                    className="mx-3"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="form_body">
            {currentStep === 4 && (
              <div className="container_form">
                <h2>Payment Details</h2>
                <div className="sub_container">
                  <div>
                    <p>Order Summary</p>

                    <div className="form-group">
                      <label>
                        Plan :
                        <svg
                          height="15"
                          width="15"
                          fill="#000000"
                          viewBox="0 0 512 512"
                          onClick={() => setPlanner(true)}
                          className="mx-2 cursor"
                        >
                          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                        </svg>
                      </label>
                      <div>
                        <select defaultValue="Tier 1">
                          <option value="">Select Plan</option>
                          <option value="Tier 1">Tier 1</option>
                          <option value="Tier 2">Tier 2</option>
                          <option value="Tier 3">Tier 3</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>First Payment:</label>
                      <div>
                        <div className="number_align">$ 1,200.00</div>
                        <div>-------------------------</div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Subtotal:</label>
                      <div>
                        <div className="number_align">$ 1,200.00</div>
                        <div>-------------------------</div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Sales Tax:</label>
                      <div>
                        <div className="number_align">
                          {" "}
                          (12%) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; $ 1,44.00
                        </div>
                        <div>-------------------------</div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Order Total:</label>
                      <div>
                        <div className="number_align">$ 1,344.00</div>
                        <div>-------------------------</div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <p>Card Details</p>
                    <div className="">
                      <label>Cards:</label>
                      <select className="form-control" name="paymentMethod">
                        <option>Credit Card</option>
                        <option>Debit Card</option>
                      </select>
                    </div>

                    <div className="mt-3">
                      <div>Name on Card:</div>
                      <input type="text" className="form-control mt-1" />
                    </div>

                    <div className="mt-3">
                      <label>Card No:</label>
                      <div className="register-input-container mt-1">
                        <input
                          type="text"
                          className="flex0"
                          placeholder="Enter Card Number"
                        />
                        <img src={visaImage} alt="Visa" />
                        <img src={masterImage} alt="Mastercard" />
                        <img src={amexImage} alt="American Express" />
                      </div>
                    </div>

                    <div className="flex0">
                      <div>
                        <input
                          className="expiry"
                          type="text"
                          placeholder="MM/YYYY"
                        />
                      </div>
                      <div className="">
                        <input className="cvv" type="text" placeholder="CVV" />
                      </div>
                    </div>
                    <div className="check-box-group">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="subscriptionConfirmation"
                      />
                      <label
                        className="form-check-label mt-2"
                        htmlFor="subscriptionConfirmation"
                      >
                        I want to subscribe to pig News and exclusives. Sign me
                        up
                      </label>
                    </div>
                    {/* <button className="btn_pay_now" onClick={handleLoginClick}>
                      Pay Now
                    </button> */}
                  </div>
                </div>

                <div className="btn_align">
                  <button
                    type="submit"
                    onClick={() => setCurrentStepHandler(2)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    onClick={handleLoginClick}
                    className="mx-3"
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {planBoolean && (
        <div className="courseDesc">
          <div onClick={() => setPlanner(false)} className="course-plan-cancel">
            X
          </div>

          <div className="course-planner-parent">
            <div>
              <h5>Tier 1</h5>

              <ol>
                <li>Basic Undestanding of Pieplines</li>
                <li>48 hrs session</li>
                <li>Study Materials</li>
                <li>Week based assessments</li>
                <li>Access to E-Books</li>
              </ol>
            </div>
            <div>
              <h5>Tier 2</h5>

              <ol>
                <li>Basic Undestanding of Pieplines</li>
                <li>48 hrs session</li>
                <li>Study Materials</li>
                <li>Week based assessments</li>
                <li>Access to E-Books</li>
              </ol>
            </div>
            <div>
              <h5>Tier 3</h5>

              <ol>
                <li>Basic Undestanding of Pieplines</li>
                <li>48 hrs session</li>
                <li>Study Materials</li>
                <li>Week based assessments</li>
                <li>Access to E-Books</li>
              </ol>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
